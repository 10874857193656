<template>
  <div
    class="select-button"
    :class="{ ['select-button_active']: showList }"
  >
    <x-btn
      v-bind="$attrs"
      @click="showList = !showList"
      @blur="onBlur"
    >
      <slot />
    </x-btn>
    <div
      v-if="showList"
      class="select-button__list"
    >
      <div
        v-for="item in list"
        :key="item.id"
        class="select-button__item gray-darkest-2--text"
        @mousedown="selectItem(item)"
      >
        {{ item.name }}
        <div
          v-if="item.disabled"
          v-hint="{
            popperClass: 'select-button_tooltip',
            placement: 'top',
            content: item.tooltip_text,
            triggers: ['hover'],
            delay: 100
          }"
          class="select-button__tooltip"
          @click.stop
          @mousedown.stop
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      showList: false
    }
  },
  methods: {
    onBlur () {
      this.showList = false
    },
    selectItem (item) {
      if (!item.disabled) this.$emit('onSelect', item)
    }
  }
}
</script>

<style lang="stylus">
.select-button
  position relative
  display block
  &__row
    color $colors.primary
  &__list
    position absolute
    left 50%
    transform translateX(-50%)
    box-shadow 0px 6px 16px rgba(5, 2, 55, 0.12)
    border-radius 4px
    top 103%
    z-index 2
    background #fff
  &__item
    position relative
    white-space nowrap
    height 36px
    line-height 36px
    padding 0 8px
    cursor pointer
    background #fff
    transition .1s
    user-select none
    &:nth-child(1)
      border-radius 4px 4px 0 0
    &:last-of-type
      border-radius 0 0 4px 4px
    &:hover
      background $colors.primary-lightest-1
  &__tooltip
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    background rgba(255,255,255,.5)
    cursor default
  & .x-btn__append
    transition .1s
    width 12px
    height 12px

.select-button_active .x-btn__append
  transform rotate(180deg)

</style>
