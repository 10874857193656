var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"products-grid"},[_c('div',{staticClass:"products-grid__spacer"},[(_vm.checkedProductsIds.length)?_c('span',{staticClass:"products-grid__count"},[_vm._v(" Отмечено: "),_c('strong',[_vm._v(_vm._s(_vm.checkedProductsIds.length))])]):_vm._e()]),(!_vm.isLoading)?_c('div',{staticClass:"products-grid__grid"},[_c('grid',{attrs:{"auto":"","actions-heading":"","select-by":"offer_id","selectable":!!_vm.products.length,"columns":_vm.columns,"cells":_vm.products,"checked-cells-ids":_vm.checkedProductsIds,"errors":_vm.errors},on:{"cell-checked":_vm.checkProduct,"check-all":_vm.checkAllProducts},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var row = ref.row;
return [_c('grid-link',{attrs:{"url":row.marketplace_url}},[_vm._v(" "+_vm._s(row.marketplace_external_id)+" ")]),(row.offer_external_id)?_c('div',{staticClass:"products-grid__meta"},[_vm._v(" Артикул: "+_vm._s(row.offer_external_id)+" ")]):_vm._e(),(row.barcode)?_c('div',{staticClass:"products-grid__meta"},[_vm._v(" Штрихкод: "+_vm._s(row.barcode)+" ")]):_vm._e()]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" "),(row.brand)?_c('div',{staticClass:"products-grid__brand"},[_vm._v(" Бренд: "+_vm._s(row.brand)+" ")]):_vm._e()]}},{key:"image",fn:function(ref){
var image = ref.image;
return [(image)?_c('img',{staticClass:"products-grid__image",attrs:{"src":image,"alt":"product"}}):_c('svg-icon',{staticClass:"products-grid__image",attrs:{"name":"no-image"}})]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('x-badge',{staticClass:"mb-2",attrs:{"color":_vm.statusColor(row.status_group),"size":"m"}},[_vm._v(" "+_vm._s(row.status_group_title)+" ")]),_c('div',{staticClass:"d-flex align-center"},[(row.requires_seller_attention)?_c('x-icon',{staticClass:"mr-1 warning--text",attrs:{"name":"alert-triangle","size":"xxs"}}):_vm._e(),_c('div',{staticClass:"gray-darker--text text"},[_vm._v(" "+_vm._s(row.status_title)+" ")])],1)]}},{key:"price",fn:function(ref){
var price = ref.price;
return [_vm._v(" "+_vm._s(_vm.formatPrice(price))+" ")]}}],null,false,270373493)}),(!_vm.shops.length)?_c('no-shops',{attrs:{"data":"по товарам"}}):_vm._e()],1):_vm._e(),(_vm.isExportInProgress)?[_c('notification',{staticClass:"notification-empty-table mt-2 mx-auto",attrs:{"type":"info"}},[_c('div',{staticClass:"mr-2"},[_c('x-loader',{attrs:{"size":"s"}})],1),_c('div',[_vm._v(" Мы уже "),_c('strong',[_vm._v("загружаем")]),_vm._v(" ваши товары в "+_vm._s(_vm.companyDetails.platformName)+". Это занимает от "),_c('strong',[_vm._v("15 минут до 2 часов.")]),_c('br'),_c('strong',[_vm._v("Пришлем письмо")]),_vm._v(", когда товары будут загружены. Спасибо за терпение! ")])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }