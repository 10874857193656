<template>
  <div
    class="notification"
    :class="notificationClasses"
  >
    <div
      class="notification__in scrollbar_primary"
      :style="`max-height: ${maxHeight}px;`"
    >
      <svg-icon
        v-if="icon"
        :name="icon"
        class="notification__icon"
      />
      <slot />
    </div>
  </div>
</template>

<script>
const types = [
  'success',
  'error',
  'warning',
  'info'
]

export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    maxHeight: {
      type: [String, Number],
      default: ''
    }
  },

  computed: {
    notificationClasses () {
      const currentType = types.find(t => t === this.type)
      return {
        [`notification_viewtype_${currentType}`]: currentType,
        'notification_fixed-height': this.maxHeight
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .notification
    width 100%
    padding 8px
    font-size 14px
    background-color #cacad3
    border-radius 4px
    box-sizing border-box

    &__in
      display flex
      align-items center

    &__icon
      flex-shrink 0
      flex-grow 0
      width 13px
      height 13px
      margin-right 10px

    &_viewtype_success
      background-color #EEFFF6

    &_viewtype_error
      background-color #FFEBEE

    &_viewtype_warning
      background-color #FFF4EC

    &_viewtype_info
      background-color #E7F4FF

    &_fixed-height
      .notification__in
        display block
        overflow-y scroll
</style>
