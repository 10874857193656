<template>
  <ul class="product-buttons">
    <template v-if="isAliMarketplace">
      <li class="product-buttons__item">
        <select-button
          v-if="isPriceAndStocksAccessed"
          prepend="refresh-right"
          append="dropdown-down"
          outline
          :list="updateList"
          @onSelect="onSelect"
        >
          Обновить
        </select-button>
      </li>

      <li class="product-buttons__item">
        <x-btn
          v-if="isPriceAndStocksAccessed"
          outline
          :disabled="syncDisabled"
          @click="$emit('sync')"
        >
          Синхронизировать цены
        </x-btn>
      </li>
    </template>
    <li class="product-buttons__item">
      <select-button
        prepend="download"
        append="dropdown-down"
        :loading="isDownloadReportPending || downloadPending"
        :list="exportList"
        @onSelect="onSelect"
      >
        Выгрузить
      </select-button>
    </li>
  </ul>
</template>

<script>
// import DownloadButton from '@/components/Interface/DownloadButton.vue'
import marketplaceCodes from '@/constants/marketplace-codes'
import { createNamespacedHelpers } from 'vuex'
import SelectButton from '@/components/Interface/SelectButton.vue'
// import { isProd } from '@/utils/system'

const { mapState } = createNamespacedHelpers('profile')
const { mapGetters } = createNamespacedHelpers('profile')

export default {
  components: {
    // DownloadButton,
    SelectButton
  },

  props: {
    shop: {
      type: Object,
      default: () => ({})
    },
    isDownloadReportPending: Boolean,
    downloadPending: Boolean,
    syncDisabled: Boolean,
    isManager: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState(['user']),
    ...mapGetters(['isUserAccessedToSection']),

    isPriceAndStocksAccessed () {
      return this.isUserAccessedToSection({
        section: 'price_and_stocks',
        shopId: this.shop.id
      })
    },

    isAliMarketplace () {
      return this.shop.marketplace_code === marketplaceCodes.ALI
    },

    isOzonMarketplace () {
      return this.shop.marketplace_code === marketplaceCodes.OZON
    },

    isWildberriesMarketplace () {
      return this.shop.marketplace_code === marketplaceCodes.WILDBERRIES
    },

    showDownloadReport () {
      // if (isProd) return
      return this.isAliMarketplace || this.isOzonMarketplace || this.isWildberriesMarketplace
    },
    updateList () {
      return [
        {
          id: 'update_list',
          name: 'Обновить список товаров',
          emit: 'update',
          disabled: !this.shop.is_manual_products_import_allowed,
          tooltip_text: 'Обновление доступно не чаще чем раз в час'
        },
        {
          id: 'update_stocks',
          name: 'Обновить цены и остатки',
          emit: 'upload',
          disabled: false
        }
      ]
    },
    exportList () {
      if (this.showDownloadReport) {
        let result = [
          {
            id: 'unload_supplies',
            name: 'Выгрузить прогноз поставок',
            emit: 'download-report',
            disabled: false
          },
          {
            id: 'upload_xlsx',
            name: 'Выгрузить в Excel',
            emit: 'download',
            disabled: false
          }
        ]
        if (this.isManager) {
          result = [
            {
              id: 'upload_xlsx',
              name: 'Выгрузить в Excel',
              emit: 'download',
              disabled: false
            }
          ]
        }
        return result
      }
      return [
        {
          id: 'upload_xlsx',
          name: 'Выгрузить в Excel',
          emit: 'download',
          disabled: false
        }
      ]
    }
  },
  methods: {
    onSelect (item) {
      this.$emit(item.emit)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .product-buttons
    display flex
    align-items center
    list-style none

    &__item
      margin-left 10px
</style>
