const widthStyle = { display: 'block', minWidth: '110px' }

export default [{
  name: 'ID на маркетплейсе',
  freezed: true,
  key: 'id',
  styles: widthStyle,
  type: 'id'
}, {
  name: 'Наименование',
  styles: { display: 'block', width: '160px', color: '#7b7b85' },
  key: 'name',
  type: 'name'
}, {
  name: 'Фото',
  styles: widthStyle,
  key: 'image_url',
  type: 'image'
}, {
  name: 'Категория',
  styles: widthStyle,
  key: 'category'
}, {
  name: 'Статус',
  key: 'status',
  type: 'status',
  styles: { display: 'block', whiteSpace: 'nowrap' }
}, {
  name: 'Цена в MMP, руб',
  styles: widthStyle,
  styleType: 'numbers',
  key: 'connector_price',
  type: 'price'
}, {
  name: 'Цена со скидкой в MMP, руб',
  styles: widthStyle,
  styleType: 'numbers',
  key: 'connector_discount_price',
  type: 'price'
}, {
  name: 'Цена на маркетплейсе, руб',
  styles: { display: 'block', minWidth: '130px' },
  styleType: 'numbers',
  key: 'marketplace_price',
  type: 'price'
}, {
  name: 'Цена со скидкой на маркетплейсе, руб',
  styles: { display: 'block', minWidth: '130px' },
  styleType: 'numbers',
  key: 'marketplace_discount_price',
  type: 'price'
}, {
  name: 'Продано за месяц, шт',
  styles: widthStyle,
  styleType: 'numbers',
  key: 'monthly_sales'
}, {
  name: 'Остаток в MMP, шт',
  styles: widthStyle,
  styleType: 'numbers',
  key: 'connector_stock'
}, {
  name: 'Остаток на маркетплейсе, шт',
  styles: { display: 'block', minWidth: '130px' },
  styleType: 'numbers',
  key: 'marketplace_stock'
}]

export const productPricesColumns = Object.freeze([{
  name: 'ID',
  key: 'id',
  type: 'id',
  styles: widthStyle
}, {
  name: 'Наименование',
  styles: { display: 'block', width: '240px', color: '#7b7b85' },
  key: 'item_title'
}, {
  name: 'Фото',
  styles: widthStyle,
  key: 'photo_url',
  type: 'image'
}, {
  name: 'Цена старая, руб',
  key: 'old_price',
  type: 'price',
  styleType: 'numbers'
}, {
  name: 'Цена новая, руб',
  key: 'price',
  type: 'price',
  styleType: 'numbers'
}, {
  name: 'Цена со скидкой старая, руб',
  key: 'old_discount_price',
  type: 'price',
  styleType: 'numbers'
}, {
  name: 'Цена со скидкой новая, руб',
  key: 'discount_price',
  type: 'price',
  styleType: 'numbers'
}, {
  name: 'Остаток старый',
  key: 'old_stock',
  styleType: 'numbers'
}, {
  name: 'Остаток новый',
  key: 'stock',
  styleType: 'numbers'
}])

export const productDashboardColumns = Object.freeze([{
  name: 'Товар',
  styles: { display: 'block', width: '160px', color: '#7b7b85' },
  sortable: true,
  key: 'item_title'
}, {
  name: 'Категория',
  styles: widthStyle,
  sortable: true,
  key: 'category'
}, {
  name: 'Заказы, руб',
  key: 'orders_money',
  type: 'price',
  sortable: true,
  styleType: 'numbers'
}, {
  name: 'Заказы, шт',
  key: 'orders_count',
  sortable: true,
  styleType: 'numbers'
}, {
  name: 'Остаток новый',
  key: 'stock',
  styleType: 'numbers'
}, {
  name: 'Себестоимость, руб',
  key: 'price',
  type: 'price',
  editable: true,
  styleType: 'numbers'
}, {
  name: 'ABC',
  sortable: true,
  key: 'category_abc'
}])
