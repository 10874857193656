<template>
  <modal
    :name="modalName"
    height="auto"
    width="540px"
    @before-close="$emit('close')"
  >
    <div class="modal-body">
      <close-button @click="$modal.hide(modalName)" />

      <svg-icon
        name="refresh"
        class="modal-body__icon"
      />

      <heading level="3">
        Синхронизация цен
      </heading>

      <span class="modal-body__text">
        Синхронизировать цены по выбранным товарам
      </span>

      <div class="modal-body__buttons">
        <x-btn
          color="gray"
          class="modal-body__action_a"
          @click="$emit('click', 'on')"
        >
          Как в ЛК
        </x-btn>

        <x-btn
          class="modal-body__action_b"
          @click="$emit('click', 'from')"
        >
          Как на маркетплейсе
        </x-btn>
      </div>
    </div>
  </modal>
</template>

<script>
import CloseButton from '@/components/Interface/CloseButton.vue'
import Heading from '@/components/Common/Heading.vue'

export default {
  components: {
    CloseButton,
    Heading
  },

  data () {
    return {
      modalName: 'sync-price-modal'
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-body
    padding 30px
    text-align center

    &__icon
      width 56px
      height 49px
      margin-bottom 20px
      stroke #5b40ff

    &__text
      display block
      max-width 481px
      color #7b7b85

    &__buttons
      margin-top 20px

    &__action
      &_a
        width 90px
        margin-right 20px

      &_b
        width 171px
</style>
