<template>
  <modal
    :name="modalName"
    height="auto"
  >
    <div class="modal-body">
      <close-button @click="$modal.hide(modalName)" />

      <div class="modal-body__head">
        <svg-icon
          name="download"
          class="modal-body__icon"
        />

        <heading
          level="3"
          class="modal-body__heading"
        >
          Загрузка новых цен и/или остатков
        </heading>
      </div>

      <span class="modal-body__text">
        Выгрузите Excel шаблон для обновления цен и стоков в разделе "Список товаров" и
        после заполнения, загрузите шаблон в этом окне.
      </span>

      <file-upload
        has-reset
        class="modal-body__field"
        @reset="setFile(null)"
        @upload="setFile"
      />

      <checkbox
        v-model="blockProducts"
        class="modal-body__checkbox"
      >
        Блокировать товары с аномальным увеличением/уменьшением цены
        <tooltip>
          <svg-icon
            name="info-fill"
            class="modal-body__info-icon"
          />

          <template #popover>
            Мы заблокируем обновление цен по товарам с аномальным отклонением цены от базовой.
            Напиример если вы ошиблись и поставили цену 1 000 вместо 10 000.
            Задать уровень отклонения вы можете самостоятельно нажав на чек-бокс.
          </template>
        </tooltip>
      </checkbox>

      <validation-observer
        v-slot="{ handleSubmit }"
      >
        <the-form
          class="modal-body__form"
          @submit="handleSubmit(onSubmit)"
        >
          <x-form-item
            v-slot="validationData"
            label="Максимальное увеличение цены в процентах"
            rules="min_value:0"
            name="Максимальное увеличение цены"
            vid="maxPriceIncrease"
          >
            <x-input
              v-model="maxPriceIncrease"
              type="number"
              :disabled="!blockProducts"
              v-bind="validationData"
              class="modal-body__input"
            />
          </x-form-item>

          <x-form-item
            v-slot="validationData"
            label="Максимальное уменьшение цены в процентах"
            rules="between:0,100"
            name="Максимальное уменьшение цены"
            vid="maxPriceDecrease"
          >
            <x-input
              v-model="maxPriceDecrease"
              :disabled="!blockProducts"
              type="number"
              v-bind="validationData"
              class="modal-body__input"
            />
          </x-form-item>

          <x-btn
            block
            type="submit"
            :disabled="!file"
            :loading="isFileUploading"
            class="modal-body__action_submit"
          >
            Загрузить шаблон
          </x-btn>
        </the-form>
      </validation-observer>
    </div>
  </modal>
</template>

<script>
import CloseButton from '@/components/Interface/CloseButton.vue'
import Heading from '@/components/Common/Heading.vue'
import FileUpload from '@/components/Interface/FileUpload.vue'
import Checkbox from '@/components/Interface/Checkbox.vue'
import Tooltip from '@/components/Common/Tooltip.vue'
import TheForm from '@/components/Interface/Form.vue'

export default {
  components: {
    CloseButton,
    FileUpload,
    Heading,
    Checkbox,
    Tooltip,
    TheForm
  },

  props: {
    isFileUploading: Boolean
  },

  data () {
    return {
      modalName: 'stock-prices-upload-modal',
      blockProducts: true,
      maxPriceIncrease: 50,
      maxPriceDecrease: 50,
      file: null
    }
  },

  methods: {
    setFile (file) {
      this.file = file
    },

    onSubmit () {
      if (!this.blockProducts) {
        return this.$emit('upload', { file: this.file })
      }
      this.$emit('upload', {
        file: this.file,
        maxPriceIncrease: this.maxPriceIncrease,
        maxPriceDecrease: this.maxPriceDecrease
      })
      this.setFile(null)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-body
    padding 32px

    &__head
      width 100%
      text-align center

    &__icon
      width 64px
      height 64px
      color #5b40ff

    &__heading
      color #3c3c47

    &__text
      display block
      font-size 14px
      text-align center

    &__field
      margin 20px 0

    &__checkbox
      >>> .checkbox__in
        display flex

      >>> .checkbox__text
        display flex

    &__info-icon
      width 16px
      height 16px
      margin-left 5px
      color #cacad3

    &__form
      margin-top 12px

    &__input
      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button
        -webkit-appearance none
        margin 0

    &__action
      &_submit
        margin-top 20px
</style>
