<template>
  <div
    class="grid-link"
  >
    <badge
      v-if="status"
      :status="status"
      :tooltip-message="error"
    />

    <template v-if="url">
      <slot />
      <a
        :href="url"
        target="_blank"
        rel="noopener noreferrer"
        class="grid-link__link"
      >
        <svg-icon
          name="attachment"
          class="grid-link__icon"
        />
      </a>
    </template>

    <slot v-else />
  </div>
</template>

<script>
import Badge from '@/components/Common/Badge.vue'

export default {
  components: {
    Badge
  },
  props: {
    url: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus" scoped>
  .grid-link
    display flex
    align-items center

    &__link
      display flex
      padding-left 5px
      color currentColor
      text-decoration none

    &__icon
      width 13px
      height 13px
</style>
