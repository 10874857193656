<template>
  <x-card
    class="product"
    size="xl"
  >
    <template>
      <div
        v-if="!isLoading"
        class="product__header"
      >
        <tabs
          class="product__tabs"
          :class="{'not-permit-by-tariff': !isPermitByTariff}"
          type="shop_id"
          :tabs="productTabs"
          :active="currentTab.path"
          @click="onTabChange"
        />
      </div>

      <div
        v-if="!isLoading"
        class="product__filters"
      >
        <ProductAdvices
          v-if="!isManager"
          :marketplace="currentTab.marketplace_code"
          class="mb-5"
          @click-supply-forecast-advice="onReportDownload"
        />

        <filters
          :shop="currentTab"
          :category="category"
          :brand="brand"
          :status="status"
          :updated-period="updatedPeriod"
          :search="filters.search"
          :hide-deleted="filters.hide_deleted"
          :marketplace="currentTab.marketplace_code"
          :class="{'not-permit-by-tariff': !isPermitByTariff}"
          @change:category="onFilterSelect('category', $event)"
          @change:brand="onFilterSelect('brand', $event)"
          @change:updated="onUpdatedFilterChange"
          @change:shop="onShopFilterChange"
          @change:status="onFilterSelect('status', $event)"
          @change:search="term => filters.search = term"
          @change:deleted="filter => filters.hide_deleted = filter"
          @reset="resetFilters"
        />
      </div>
      <shop-limit-alert
        v-if="!isManager"
        class="mb-2"
      />
      <template v-if="isPermitByTariff">
        <div
          v-if="isLoading || isGridLoading"
          class="product__loader"
        >
          <loader />
        </div>
        <transition name="fade-in">
          <div v-if="!isLoading && !isGridLoading">
            <notification
              v-if="isNewShop"
              type="info"
              class="product__notification mt-1 mb-2"
            >
              <div>
                Мы начинаем собирать данные <strong>с момента подключения</strong> магазина к  {{ companyDetails.platformName }}. <strong>Полный отчет по оборачиваемости</strong> сформируем спустя <strong>7 дней.</strong> Мы пришлем Вам письмо, когда он будет готов
              </div>
            </notification>
            <div
              v-if="products.length"
              class="product__actions"
            >
              <span class="product__count">
                Найдено товаров: <strong>{{ productsCount }}</strong>
              </span>

              <product-buttons
                :download-pending="isDownloadPending"
                :is-download-report-pending="isDownloadReportPending"
                :sync-disabled="!checkedUnsyncedProducts.length"
                :shop="currentTab"
                :is-manager="isManager"
                @download="onDownloadExcel"
                @sync="onSync"
                @upload="onPriceRefresh"
                @update="onUpdateList"
                @download-report="onReportDownload"
              />
            </div>

            <notification
              v-if="unsyncedProducts.length"
              type="error"
              class="product__notification"
            >
              <div>
                <svg-icon
                  name="error-triangle"
                  class="product__notification-icon"
                />
                По <strong>{{ unsyncedProducts.length }} товарам</strong> цены в ЛКП и цены в маркетплейсе отличаются.
                Вы можете синхронизировать их, нажав кнопку
                <strong>Синхронизировать цены.</strong>
              </div>
            </notification>

            <products-grid
              :unsynced-products="unsyncedProducts"
              :is-loading="isLoading"
              :marketplace="currentTab.marketplace_code"
              :shop="currentTab"
              class="product__grid"
              @change:checkedProductsIds="ids => checkedProductsIds = ids"
            />
            <div
              v-if="products.length"
              class="product__pagination"
            >
              <pagination
                :data="pagination"
                :current-page="currentPage"
                @change="onPaginationChange"
              />
            </div>
          </div>
        </transition>
      </template>
      <not-permit-by-tariff v-else />
      <datepicker-modal
        identity="report"
        :max-date="new Date()"
      >
        <template #actions="{ date }">
          <validation-observer v-slot="{ handleSubmit }">
            <seller-form @submit="handleSubmit(() => onDownloadReport(date))">
              <x-form-item
                v-slot="validationData"
                label="Количество дней до поставки"
                name="Количество дней до поставки"
                rules="required|isNumber|greaterThanZero"
                class="form-fields-list__item"
              >
                <x-input
                  v-model="days_before_supply"
                  v-bind="validationData"
                  width="390px"
                  placeholder="Введите кол-во дней"
                />
              </x-form-item>
              <x-btn
                block
                type="submit"
                :loading="isDownloadReportPending"
              >
                Выбрать
              </x-btn>
            </seller-form>
          </validation-observer>
        </template>
      </datepicker-modal>

      <sync-price-modal
        @click="onPriceSyncClick"
      />

      <stock-prices-upload-modal
        :is-file-uploading="isFileUploading"
        @upload="onStockPricesUpload"
      />

      <updatable-products-modal
        @success="$modal.show('update-processed-modal')"
      />

      <update-processed-modal />
      <export-modal />
    </template>
  </x-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { defaultFilterOption } from '@/constants/dashboards-filters'
import Tabs from '@/components/Interface/Tabs.vue'
import ProductAdvices from '@/components/Product/ProductAdvices/ProductAdvices'
import Pagination from '@/components/Interface/Pagination.vue'
import Filters from '@/components/Product/Filter/Filter.vue'
import Loader from '@/components/Common/Loader.vue'
import ProductButtons from '@/components/Product/Filter/Buttons.vue'
import ProductsGrid from '@/components/Product/ProductsGrid.vue'
import SyncPriceModal from '@/components/Modals/SyncPriceModal.vue'
import ExportModal from '@/components/Modals/ExportModal.vue'
import Notification from '@/components/Interface/Notification.vue'
import StockPricesUploadModal from '@/components/Modals/StockPricesUploadModal'
import UpdatableProductsModal from '@/components/Modals/UpdatableProductsModal'
import UpdateProcessedModal from '@/components/Modals/UpdateProcessedModal.vue'
import DatepickerModal from '@/components/Modals/DatepickerModal.vue'
import SellerForm from '@/components/Interface/Form.vue'
import PaginationMixin from '@/mixins/pagination'
import companyDetails from '@/plugins/companyDetails'
import NotPermitByTariff from '@/components/NotPermit/NotPermitByTariff'

import { cloneDeep } from 'lodash'
import moment from 'moment'
import PERMISSION_KEY from '@/constants/PERMISSION_KEY'
import NotPermitModal from '@/components/NotPermit/NotPermitModal'
import ShopLimitAlert from '@/components/NotPermit/ShopLimitAlert'

const { mapActions, mapState } = createNamespacedHelpers('products')
const { mapActions: mapShopsActions, mapState: mapShopsState } = createNamespacedHelpers('marketplaces')
const { mapGetters, mapState: mapProfileState } = createNamespacedHelpers('profile')
const { mapActions: mapTrackingActions } = createNamespacedHelpers('tracking')

const DEFAULT_FILTERS = {
  status: null,
  updated_from: null,
  updated_to: null,
  shop: null,
  brand: null,
  category: null,
  search: null,
  hide_deleted: true
}

export default {
  mixins: [PaginationMixin],

  components: {
    ShopLimitAlert,
    Tabs,
    ProductAdvices,
    Filters,
    Pagination,
    Loader,
    ProductButtons,
    ProductsGrid,
    SyncPriceModal,
    Notification,
    ExportModal,
    StockPricesUploadModal,
    UpdatableProductsModal,
    UpdateProcessedModal,
    DatepickerModal,
    SellerForm,
    NotPermitByTariff
  },

  data () {
    return {
      checkedProductsIds: [],
      shop: defaultFilterOption,
      category: defaultFilterOption,
      brand: defaultFilterOption,
      status: defaultFilterOption,
      updatedPeriod: {
        start: null,
        end: null
      },
      days_before_supply: null,
      filters: cloneDeep(DEFAULT_FILTERS),
      isLoading: false,
      isGridLoading: false,
      isFileUploading: false,
      isDownloadPending: false,
      isDownloadReportPending: false,
      companyDetails
    }
  },

  watch: {
    filters: {
      handler () {
        if (this.currentPage === 1) {
          this.fetchData()
          return
        }
        this.currentPage = 1
      },
      deep: true
    }
  },

  computed: {
    ...mapState(['products']),
    ...mapShopsState(['shops']),
    ...mapGetters(['isUserAccessedToSection']),
    ...mapProfileState(['user']),

    isNewShop () {
      return moment().diff(this.currentTab.created, 'days') < 7
    },

    accessedShops () {
      return this.shops.filter(shop => {
        return this.isUserAccessedToSection({
          section: 'access_products',
          shopId: shop.id
        })
      })
    },

    productTabs () {
      return this.accessedShops.map(shop => ({
        ...shop,
        path: shop.id,
        title: shop.shop.name,
        counter: shop.product_count,
        icon: shop.marketplace_code,
        disabled: shop.is_paywalled_in_lkp
      }))
    },

    activeTab () {
      return +this.$route.query?.shop_id || this.productTabs[0]?.path
    },

    currentTab () {
      if (!this.productTabs.length) return {}

      return this.productTabs.find(t => t.path === this.activeTab)
    },

    productsCount () {
      return this.pagination.count
    },

    unsyncedProducts () {
      return this.products.filter(product => {
        return product.connector_price !== product.marketplace_price || product.marketplace_stock !== product.connector_stock
      })
    },

    checkedUnsyncedProducts () {
      return this.unsyncedProducts.filter(product => this.checkedProductsIds.includes(product.offer_id))
    },

    isPermitByTariff () {
      return this.$user.isPermitByTariff(PERMISSION_KEY.SELLER_PRODUCT_LIST)
    },

    isManager () {
      return this.user.service_model && this.user.service_model === 'xway-service'
    }
  },

  async created () {
    try {
      this.isLoading = true

      await this.getShopList()
      if (!this.shops.length) return

      await this.fetchData()
    } finally {
      this.isLoading = false
    }
  },

  methods: {
    ...mapActions([
      'getProducts',
      'updateProductsList',
      'downloadProducts',
      'downloadTurnoverReport',
      'syncPriceAndStocks',
      'uploadProducts',
      'getProductStatuses',
      'clearProductStatuses'
    ]),
    ...mapShopsActions(['getShopList']),
    ...mapTrackingActions(['setEvent']),

    async fetchData (params = {}) {
      try {
        this.isGridLoading = true

        let payload = Object
          .keys(this.filters)
          .filter(key => this.filters[key] && this.filters[key] !== defaultFilterOption.id)
          .reduce((acc, key) => {
            acc[key] = this.filters[key]

            return acc
          }, {})

        payload = {
          params: {
            page: this.currentPage,
            limit: this.pagination.limit,
            ...this.filters,
            ...payload,
            ...params,
            shop: this.activeTab
          }
        }

        this.getProductStatuses({ shop: this.activeTab })
        this.pagination = { ...this.pagination, ...await this.getProducts(payload) }
      } finally {
        this.isGridLoading = false
      }
    },

    async onDownloadExcel () {
      this.setEvent({
        name: 'mmp_productlist_report_excel',
        product: 'MMP',
        screen: 'mmp_productlist',
        shop_id: this.currentTab.id,
        marketplace_code: this.currentTab.marketplace_code,
        report_id: 1
      })
      try {
        this.isDownloadPending = true

        let payload = {
          ...this.filters,
          shop: this.activeTab
        }
        if (this.checkedProductsIds.length) {
          payload = {
            shop: this.activeTab,
            item_offer_ids: this.checkedProductsIds
          }
        }

        const success = await this.downloadProducts(payload)

        if (success) {
          this.$modal.show('export-modal')
        }
      } finally {
        this.isDownloadPending = false
      }
    },

    async onPriceSyncClick (target) {
      if (target === 'on') {
        this.setEvent({
          name: 'mmp_productlist_synch_lk',
          product: 'MMP',
          screen: 'mmp_productlist',
          shop_id: this.currentTab.id,
          marketplace_code: this.currentTab.marketplace_code
        })
      } else if (target === 'from') {
        this.setEvent({
          name: 'mmp_productlist_synch_mp',
          product: 'MMP',
          screen: 'mmp_productlist',
          shop_id: this.currentTab.id,
          marketplace_code: this.currentTab.marketplace_code
        })
      }
      try {
        const success = await this.syncPriceAndStocks({
          target,
          ids: this.checkedUnsyncedProducts.map(p => p.offer_id),
          shop: this.activeTab
        })
        if (success) {
          this.$toast.success('Задача на синхронизацию цен и остатков успешно запущена')
        }
      } finally {
        this.$modal.hide('sync-price-modal')
      }
    },

    async onStockPricesUpload (payload) {
      this.setEvent({
        name: 'mmp_productlist_priceupdate_download',
        product: 'MMP',
        screen: 'mmp_productlist',
        shop_id: this.currentTab.id,
        marketplace_code: this.currentTab.marketplace_code,
        max_price_increase: payload.maxPriceIncrease,
        max_price_decrease: payload.maxPriceDecrease
      })
      try {
        this.isFileUploading = true
        const listId = await this.uploadProducts(payload)
        this.$modal.show('updatable-products-modal', { listId })
      } finally {
        this.$modal.hide('stock-prices-upload-modal')
        this.isFileUploading = false
      }
    },

    async onDownloadReport (period) {
      this.$metrics.hit('mmp_productlist_report_turnover_filters')

      if (this.$user.isPermitByTariff(PERMISSION_KEY.SELLER_REPORT_TURNOVER)) {
        const { end, start } = period
        try {
          this.isDownloadReportPending = true

          const success = await this.downloadTurnoverReport({
            ...this.filters,
            shop: this.activeTab,
            item_offer_ids: this.checkedProductsIds,
            days_before_supply: this.days_before_supply,
            date_from: start,
            date_to: end
          })

          if (success) {
            this.$modal.show('export-modal')
          }
        } finally {
          this.isDownloadReportPending = false
        }
      } else {
        this.$dialog.show(NotPermitModal)
      }
    },

    onTabChange (shop) {
      this.filters.shop = shop
      this.checkedProductsIds = []
      this.clearProductStatuses()
      this.resetFilters()
    },

    resetFilters () {
      Object.keys(this.filters).forEach(key => {
        if (key === 'shop') return
        this.filters[key] = DEFAULT_FILTERS[key]
      })
      this.shop = defaultFilterOption
      this.category = defaultFilterOption
      this.brand = defaultFilterOption
      this.status = defaultFilterOption
      this.updatedPeriod = {
        start: null,
        end: null
      }
    },

    onFilterSelect (type, value) {
      this.filters[type] = value.id !== 'ALL' ? value.id : null
      this[type] = value
    },

    onPaginationChange ({ page, limit, offset }) {
      this.currentPage = page

      this.pagination.limit = limit

      this.fetchData({ page, limit, offset })
    },

    onUpdatedFilterChange (period) {
      const { start, end } = period
      this.filters.updated_from = start
      this.filters.updated_to = end

      this.updatedPeriod = period
    },

    onShopFilterChange (shop) {
      this.resetFilters()
      this.onFilterSelect('shop', shop)
    },

    goToProductView (cell) {
      this.$router.push({ name: 'product.info', params: { product: cell, id: cell.id } })
    },

    onSync () {
      this.$modal.show('sync-price-modal')
      this.setEvent({
        name: 'mmp_productlist_synch',
        product: 'MMP',
        screen: 'mmp_productlist'
      })
    },

    onPriceRefresh () {
      this.$modal.show('stock-prices-upload-modal')
      this.setEvent({
        name: 'mmp_productlist_priceupdate',
        product: 'MMP',
        screen: 'mmp_productlist',
        shop_id: this.currentTab.id,
        marketplace_code: this.currentTab.marketplace_code
      })
    },

    onReportDownload () {
      this.$modal.show('report-datepicker-modal')
      this.setEvent({
        name: 'mmp_productlist_report_turnover',
        product: 'MMP',
        screen: 'mmp_productlist',
        shop_id: this.currentTab.id,
        marketplace_code: this.currentTab.marketplace_code,
        report_id: 1
      })
    },

    async onUpdateList () {
      await this.updateProductsList(this.activeTab)
      await this.getShopList()
      this.fetchData()
    }
  }
}
</script>

<style lang="stylus">
  .product
    &__tabs
      .tabs__icon
        width 20px
        height 20px
        border-radius 3px
    &__header
      margin-bottom 16px

    &__actions
      display flex
      justify-content space-between
      align-items center

    &__loader
      padding 40px
      text-align center

    &__count
      font-size 14px
      color #3c3c47

    &__notification
      margin 20px 0 5px
      border 0

    &__notification-icon
      width 12px
      height 12px
      margin-right 10px

    &__grid
      margin-bottom 10px

    &__caption
      margin-bottom 10px

  .fade-in-enter-active
    transition opacity .2s ease-in

  .fade-in-enter,
  .fade-in-leave-to
    opacity 0
</style>
