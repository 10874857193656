<template>
  <x-btn
    v-bind="$attrs"
    :href="path"
    :download="fileName"
    :loading="loading"
    class="download"
    :metrics="metrics"
    @click="onClick()"
  >
    <x-icon
      name="download"
      size="xs"
      :class="iconClasses"
    />
    <slot />
  </x-btn>
</template>

<script>
import { downloadFileFromResponse } from '@/utils/downloadFileFromResponse'

export default {
  name: 'DownloadButton',

  props: {
    path: {
      type: String,
      default: null
    },
    fileName: {
      type: String,
      default: ''
    },
    metrics: String,

    action: Function
  },

  data: () => ({
    loading: false
  }),

  computed: {
    iconClasses () {
      return ['download__icon', { 'download__icon_single': !this.$slots.default }]
    }
  },

  methods: {
    async onClick () {
      this.$emit('click')
      if (typeof this.action !== 'function') return

      this.loading = true
      try {
        const res = await this.action()

        downloadFileFromResponse(res)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .download
    &__icon
      margin-right 5px
      color currentColor

      &_single
        margin-right 0
</style>
