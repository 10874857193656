<template>
  <modal
    :name="modalName"
    height="auto"
    @before-open="onBeforeOpen"
  >
    <div class="modal-body">
      <div class="modal-body__content">
        <close-button @click="$modal.hide(modalName)" />

        <svg-icon
          name="play--round"
          class="modal-body__icon"
        />

        <heading level="3">
          Мы запустили процесс обновления
        </heading>

        <div class="modal-body__text">
          После завершения процесса мы сделаем запись в
          <router-link
            class="link"
            :to="{ name: 'product.stock.prices' }"
          >
            Истории обновления Цен и Остатков
          </router-link>,
          а вы получите уведомление на почту.
        </div>

        <x-btn
          block
          class="modal-body__btn"
          @click="$modal.hide(modalName)"
        >
          Закрыть
        </x-btn>
      </div>

      <checkbox
        :model-value="shouldNotShow"
        @change="toggleShow"
      >
        Больше не показывать
      </checkbox>
    </div>
  </modal>
</template>

<script>
import CloseButton from '@/components/Interface/CloseButton.vue'
import Heading from '@/components/Common/Heading.vue'
import Checkbox from '@/components/Interface/Checkbox.vue'

export default {
  components: {
    CloseButton,
    Heading,
    Checkbox
  },

  data () {
    return {
      modalName: 'update-processed-modal',
      storageName: 'seller_show_update_processed_modal',
      shouldNotShow: false
    }
  },

  methods: {
    onBeforeOpen (e) {
      const flag = JSON.parse(localStorage.getItem(this.storageName))
      const shouldShow = Boolean(flag ?? 1)
      if (!shouldShow) {
        e.cancel()
      }
    },

    toggleShow (hide) {
      localStorage.setItem(this.storageName, hide ? 0 : 1)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-body
    padding 32px

    &__content
      text-align center

    &__icon
      width 64px
      height 64px
      color #5b40ff

    &__btn
      margin 20px 0
</style>
