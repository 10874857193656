export function downloadFileFromResponse (res) {
  let filename = ''

  const disposition = res.headers['content-disposition']

  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(disposition)

    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '')
    }
  }

  const a = document.createElement('a')
  const objUrl = URL.createObjectURL(new Blob([res.data]))

  a.href = objUrl
  a.download = filename
  a.click()

  URL.revokeObjectURL(objUrl)
}
