<template>
  <x-collapse
    title="Подробная товарная аналитика"
    storage-id="product-advices"
    metrics-close="mmp_productlist_collapse_close"
    metrics-open="mmp_productlist_collapse_open"
  >
    <div class="row">
      <div class="col">
        <x-advice-card
          :disabled="disabled"
          src="./product-advice-positions.png"
          title="По каким товарам ваши товары ищутся в поиске и какое место занимают в категориях?"
        >
          Смотрите отчет по позициям для каждого товара в «Товарной аналитике»
          <template #actions>
            <x-advice-card-btn
              :href="reportsHref"
              target="_self"
              metrics="mmp_productlist_hint_analytics_reports_positions"
            >
              {{ disabled ? 'Скоро' : 'Смотреть отчёт' }}
            </x-advice-card-btn>
          </template>
        </x-advice-card>
      </div>
      <div class="col">
        <x-advice-card
          :disabled="disabled"
          src="./product-advice-content.png"
          title="Чего не хватает в ваших карточах товаров, чтобы стать лучше конкурентов и выйти в топ?"
        >
          Смотрите анализ контента карточки товара для каждого товара в «Товарной аналитике»
          <template #actions>
            <x-advice-card-btn
              :href="analyticsHref"
              target="_self"
              metrics="mmp_productlist_hint_analytics_products_content"
            >
              {{ disabled ? 'Скоро' : 'Смотреть анализ' }}
            </x-advice-card-btn>
          </template>
        </x-advice-card>
      </div>
      <div class="col">
        <x-advice-card
          src="./product-advice-supplies.png"
          title="Где вы теряете деньги из-за неправильных поставок или хранения товаров?"
        >
          Скачивайте отчет «Прогноз поставок и отчет по оборачиваемости»
          <template #actions>
            <x-advice-card-btn
              metrics="mmp_productlist_hint_turnover_report"
              @click="$emit('click-supply-forecast-advice')"
            >
              Скачать отчёт
            </x-advice-card-btn>
          </template>
        </x-advice-card>
      </div>
      <div class="col">
        <x-advice-card
          :disabled="disabled"
          src="./product-advice-competitors.png"
          title="Хотите ежедневно получать цены товаров-конкурентов?"
        >
          Добавьте конкурентов и смотрите отчет в «Отчетах по товарам»
          <template #actions>
            <x-advice-card-btn
              :href="reportsHref"
              target="_self"
              metrics="mmp_productlist_hint_analytics_reports_competitors"
            >
              {{ disabled ? 'Скоро' : 'Добавить конкурентов' }}
            </x-advice-card-btn>
          </template>
        </x-advice-card>
      </div>
      <!--      <div class="col">
        <x-advice-card
          src="@/assets/images/onboarding/order_recommendations.png"
          title="Заказать рекомендации по прогнозу поставок и маркетинговым отчетам"
          :disabled="orderLoading"
        >
          Посмотрим ваши отчеты, расшифруем, сделаем выводы и расскажем,
          что с этим делать, чтобы увеличить выручку от продаж.
          <template #actions>
            <x-advice-card-btn
              @click="order"
            >
              Заказать
            </x-advice-card-btn>
          </template>
        </x-advice-card>
      </div>-->
      <div
        v-if="isWildberries"
        class="col"
      >
        <x-advice-card
          src="./product-advice-categories.png"
          title="Ищите новые категории или товары для продажи"
        >
          Проанализируйте категории на маркетплейсах, найдите наиболее трендовые и менее конкурентные категории, наблюдайте за товарами конкурентов в сервисе «Анализ рынка»
          <template #actions>
            <x-advice-card-btn
              :href="categoriesHref"
              target="_self"
              metrics="mmp_productlist_hint_orm_categories"
            >
              Перейти
            </x-advice-card-btn>
          </template>
        </x-advice-card>
      </div>
    </div>
  </x-collapse>
</template>

<script>
import orderRecommendations from '@/api/default/modules/orderingRecommendations'

export default {
  props: {
    marketplace: String
  },
  data: () => ({
    reportsHref: process.env.VUE_APP_INTERNAL_ANALYTICS_URL + '/reports',
    analyticsHref: process.env.VUE_APP_INTERNAL_ANALYTICS_URL + '/products',
    categoriesHref: process.env.VUE_APP_ORM_URL + '/categories',
    orderLoading: false
  }),
  computed: {
    isOzon () {
      return this.marketplace === 'ozon'
    },
    isWildberries () {
      return this.marketplace === 'wildberries'
    },
    disabled () {
      return !this.isOzon && !this.isWildberries
    }
  },
  methods: {
    async order () {
      this.orderLoading = true
      await orderRecommendations.call(this)
      this.orderLoading = false
    }
  }
}
</script>
