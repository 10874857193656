<template>
  <div class="products-grid">
    <div class="products-grid__spacer">
      <span
        v-if="checkedProductsIds.length"
        class="products-grid__count"
      >
        Отмечено: <strong>{{ checkedProductsIds.length }}</strong>
      </span>
    </div>

    <div
      v-if="!isLoading"
      class="products-grid__grid"
    >
      <grid
        auto
        actions-heading=""
        select-by="offer_id"
        :selectable="!!products.length"
        :columns="columns"
        :cells="products"
        :checked-cells-ids="checkedProductsIds"
        :errors="errors"
        @cell-checked="checkProduct"
        @check-all="checkAllProducts"
      >
        <template #id="{ row }">
          <grid-link
            :url="row.marketplace_url"
          >
            {{ row.marketplace_external_id }}
          </grid-link>

          <div
            v-if="row.offer_external_id"
            class="products-grid__meta"
          >
            Артикул: {{ row.offer_external_id }}
          </div>
          <div
            v-if="row.barcode"
            class="products-grid__meta"
          >
            Штрихкод: {{ row.barcode }}
          </div>
        </template>

        <template #name="{ row }">
          {{ row.name }}
          <div
            v-if="row.brand"
            class="products-grid__brand"
          >
            Бренд: {{ row.brand }}
          </div>
        </template>

        <template #image="{ image }">
          <img
            v-if="image"
            :src="image"
            alt="product"
            class="products-grid__image"
          >
          <svg-icon
            v-else
            name="no-image"
            class="products-grid__image"
          />
        </template>

        <template #status="{ row }">
          <x-badge
            class="mb-2"
            :color="statusColor(row.status_group)"
            size="m"
          >
            {{ row.status_group_title }}
          </x-badge>
          <div class="d-flex align-center">
            <x-icon
              v-if="row.requires_seller_attention"
              class="mr-1 warning--text"
              name="alert-triangle"
              size="xxs"
            />
            <div class="gray-darker--text text">
              {{ row.status_title }}
            </div>
          </div>
        </template>

        <template #price="{ price }">
          {{ formatPrice(price) }}
        </template>
      </grid>

      <no-shops
        v-if="!shops.length"
        data="по товарам"
      />
    </div>
    <template v-if="isExportInProgress">
      <notification
        type="info"
        class="notification-empty-table mt-2 mx-auto"
      >
        <div class="mr-2">
          <x-loader size="s" />
        </div>
        <div>
          Мы уже <strong>загружаем</strong> ваши товары в {{ companyDetails.platformName }}. Это занимает от <strong>15 минут до 2 часов.</strong>
          <br><strong>Пришлем письмо</strong>, когда товары будут загружены. Спасибо за терпение!
        </div>
      </notification>
    </template>
  </div>
</template>

<script>
import Grid from '@/components/Interface/Grid.vue'
import GridLink from '@/components/Interface/GridLink.vue'
import NoShops from '@/components/Interface/NoShops.vue'
import columns from '@/constants/products'
import formatPrice from '@/utils/price-formatter'
import { createNamespacedHelpers } from 'vuex'
import Notification from '../Interface/Notification'
import companyDetails from '@/plugins/companyDetails'

const { mapState } = createNamespacedHelpers('products')
const { mapState: mapShopsState } = createNamespacedHelpers('marketplaces')

export default {
  components: {
    Notification,
    Grid,
    GridLink,
    NoShops
  },

  props: {
    unsyncedProducts: {
      type: Array,
      default: () => []
    },
    marketplace: {
      type: String,
      default: ''
    },
    isLoading: Boolean,

    shop: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      columns,
      checkedProductsIds: [],
      companyDetails
    }
  },

  watch: {
    checkedProductsIds (ids) {
      this.$emit('change:checkedProductsIds', ids)
    }
  },

  computed: {
    ...mapState(['products', 'productStatuses']),
    ...mapShopsState(['shops']),

    isExportInProgress () {
      return !this.shop.last_sync_items && this.shops?.length
    },

    errors () {
      return this.unsyncedProducts.reduce((out, product) => {
        out[`-${product.id}`] = ['']
        return out
      }, {})
    }
  },

  methods: {
    formatPrice,

    checkProduct (cell) {
      const checkedId = this.checkedProductsIds.findIndex(id => cell.offer_id === id)

      if (~checkedId) {
        this.checkedProductsIds.splice(checkedId, 1)
      } else {
        this.checkedProductsIds.push(cell.offer_id)
      }
    },

    checkAllProducts (checked) {
      if (!checked) {
        this.checkedProductsIds = []
        return
      }

      this.products.forEach(p => {
        if (this.checkedProductsIds.includes(p.offer_id)) return
        this.checkedProductsIds.push(p.offer_id)
      })
    },

    statusColor (status) {
      switch (status) {
        case 'online': return 'success-lightest-2'
        case 'offline': return 'error-lightest-3'
        case 'ready_for_sale': return 'light-blue-lighten-5'
        case 'rejected_by_marketplace': return 'warning-lightest-3'
        case 'unknown': return 'gray-lighter'
      }

      return null
    }
  }
}
</script>

<style lang="stylus" scoped>
  .products-grid
    &__image
      max-width 70px
      max-height 66px

    &__meta,
    &__brand
      margin-top 4px
      white-space nowrap
      font-size 12px
      color #9c9ca6

    &__brand
      margin-top 8px

    &__count
      font-size 14px
      color #3c3c47

    &__spacer
      height 20px

  .notification-empty-table {
    max-width 650px
  }
</style>
