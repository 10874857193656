<template>
  <ul class="filters">
    <li
      v-if="isAliMarketplace || isOzonMarketplace"
      class="filters__item"
    >
      <x-form-item
        class="field-composition_content_filter"
        name="Категория"
        label="Категория"
      >
        <x-select
          class="form-field-box_content_filter"
          track-by="id"
          :value="category"
          :options="computedCategories"
          :limit="1"
          label="label"
          :loading="isCategoriesLoading"
          :allow-empty="false"
          placeholder="Все категории"
          @select="category => $emit('change:category', category)"
          @open="onCategorySelectOpen"
        />
      </x-form-item>
    </li>

    <li
      v-if="isAliMarketplace"
      class="filters__item"
    >
      <x-form-item
        class="field-composition_content_filter"
        name="Бренд"
        label="Бренд"
      >
        <x-select
          class="form-field-box_content_filter"
          track-by="id"
          :value="brand"
          :options="computedBrands"
          :limit="1"
          label="label"
          :loading="isBrandsLoading"
          :allow-empty="false"
          placeholder="Все бренды"
          @select="brand => $emit('change:brand', brand)"
          @open="onBrandSelectOpen"
        />
      </x-form-item>
    </li>

    <li
      v-if="isAliMarketplace || isOzonMarketplace || isYandexMarketplace"
      class="filters__item"
    >
      <status-filter
        :status="status"
        :statuses="computedStatuses"
        :loading="isStatusesLoading"
        @input="status => $emit('change:status', status)"
        @open="onStatusSelectOpen"
      />
    </li>

    <li class="filters__item">
      <x-form-item
        name="Обновлён"
        label="Обновлён"
      >
        <datepicker
          border
          has-reset-button
          :value="updatedPeriod"
          @change="period => $emit('change:updated', period)"
        />
      </x-form-item>
    </li>

    <li class="filters__item filters__item_content_search">
      <search
        class="mt-2"
        :value="search"
        @input="term => $emit('change:search', term || null)"
      />
    </li>

    <li class="filters__item filters__item_content_search">
      <x-checkbox
        label="Скрыть удаленные"
        :model-value="hideDeleted"
        @change="value => $emit('change:deleted', value)"
      />
    </li>

    <li class="filters__item filters__item_content_reset">
      <reset-button
        block
        @click="$emit('reset')"
      >
        Очистить фильтры
      </reset-button>
    </li>
  </ul>
</template>

<script>
import { defaultFilterOption } from '@/constants/dashboards-filters'
import Datepicker from '@/components/Interface/Datepicker.vue'
import Search from './Search.vue'
import ResetButton from '@/components/Interface/ResetButton.vue'
import StatusFilter from '@/components/Interface/StatusFilter.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapState } = createNamespacedHelpers('products')

const emptyOption = {
  start: null,
  end: null
}

export default {
  components: {
    Datepicker,
    Search,
    ResetButton,
    StatusFilter
  },

  props: {
    shop: {
      type: Object,
      default: () => ({})
    },
    category: {
      type: Object,
      default: () => defaultFilterOption
    },
    brand: {
      type: Object,
      default: () => defaultFilterOption
    },
    status: {
      type: Object,
      default: () => defaultFilterOption
    },
    updatedPeriod: {
      type: Object,
      default: () => emptyOption
    },
    search: {
      type: String,
      default: ''
    },
    hideDeleted: {
      type: Boolean,
      default: false
    },
    marketplace: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isBrandsLoading: false,
      isCategoriesLoading: false,
      isStatusesLoading: false
    }
  },

  computed: {
    ...mapState([
      'productBrands',
      'productCategories',
      'productStatuses'
    ]),

    computedBrands () {
      const brandOptions = this.productBrands.map(b => ({ id: b.id, label: b.name }))
      brandOptions.unshift(defaultFilterOption)
      return brandOptions
    },

    computedCategories () {
      const categoryOptions = this.productCategories.map(c => ({ id: c.id, label: c.name }))
      categoryOptions.unshift(defaultFilterOption)
      return categoryOptions
    },

    computedStatuses () {
      const statusOptions = this.productStatuses.map(s => ({ id: s.status, label: s.status_title }))
      statusOptions.unshift(defaultFilterOption)
      return statusOptions
    },

    isAliMarketplace () {
      return this.marketplace === 'ali'
    },

    isOzonMarketplace () {
      return this.marketplace === 'ozon'
    },

    isYandexMarketplace () {
      return this.marketplace === 'beru'
    }
  },

  beforeDestroy () {
    this.clearProductStatuses()
  },

  methods: {
    ...mapActions([
      'getProductBrands',
      'getProductCategories',
      'getProductStatuses',
      'clearProductStatuses'
    ]),

    async onBrandSelectOpen () {
      try {
        this.isBrandsLoading = true
        await this.getProductBrands({
          shop: this.shop.id
        })
      } finally {
        this.isBrandsLoading = false
      }
    },

    async onCategorySelectOpen () {
      try {
        this.isCategoriesLoading = true
        await this.getProductCategories({
          shop: this.shop.id
        })
      } finally {
        this.isCategoriesLoading = false
      }
    },

    async onStatusSelectOpen () {
      if (this.productStatuses.length) return
      try {
        this.isStatusesLoading = true
        await this.getProductStatuses({
          shop: this.shop.id
        })
      } finally {
        this.isStatusesLoading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .filters
    display flex
    flex-wrap wrap
    align-items center
    margin 0
    margin-left -10px
    padding 0
    list-style none

    &__item
      flex 0 0 245px
      margin-left 10px
      margin-bottom 10px

      &_content_search,
      &_content_reset
        padding-top 12px

  .checkbox
    >>>.checkbox__text
      color #2c2c37
</style>
