<template>
  <div class="product">
    <div class="product__hero">
      <header class="product__hero">
        <heading class="product__heading">
          {{ $route.meta.title }}
        </heading>
      </header>

      <main class="product__content">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import Heading from '@/components/Common/Heading.vue'

export default {
  components: {
    Heading
  }
}
</script>

<style lang="stylus" scoped>
  .product
    &__hero
      margin-bottom 16px

    &__heading
      margin-bottom 0
</style>
