<template>
  <modal
    :name="modalName"
    height="auto"
    width="75%"
    adaptive
    @before-open="onBeforeOpen"
  >
    <div class="modal-body">
      <close-button @click="$modal.hide(modalName)" />

      <heading level="3">
        Проверка загруженных данных
      </heading>

      <loader
        v-if="isLoading"
        class="modal-body__loader"
      />

      <template v-else>
        <notification
          class="modal-body__info"
          icon="info-fill"
        >
          <div class="modal-body__info-text">
            Пока мы не запустили процесс обновления.
            Проверьте, пожалуйста, корректность загруженных данных, если все хорошо, нажмите кнопку “Запустить процесс“.
            После нажатия кнопки “Запустить процесс“ отмена обновления невозможна.
            Если вы ошиблись или передумали, исправить это можно только запуском новой задачи, после завершения этой”.
          </div>

          <x-btn
            color="gray"
            class="modal-body__info-btn"
            :disabled="updateDisabled"
            @click="onUpdateClick"
          >
            Запустить процесс
          </x-btn>
        </notification>

        <div class="modal-body__actions">
          <checkbox
            v-model="onlyBlocked"
            rounded
          >
            Только заблокированные
          </checkbox>

          <download-button
            color="gray"
            @click="onDownloadErrorProducts"
          >
            Скачать заблокированные товары
          </download-button>
        </div>

        <notification
          v-if="updatableProductsErrorsCount"
          type="error"
          icon="error-triangle"
          class="modal-body__notification"
        >
          <div>
            Обновление по
            <strong>
              {{ updatableProductsErrorsCount }} {{ errorsCountText }}
            </strong>
            было заблокировано.
          </div>
        </notification>

        <grid
          auto
          :cells="updatableProducts"
          :columns="columns"
          :errors="errors"
          class="modal-body__grid scrollbar_primary"
        >
          <template #id="{ row }">
            {{ row.ali_id || row.id }}
            <div
              v-if="row.vendor_code"
              class="modal-body__grid-meta"
            >
              Артикул: {{ row.vendor_code }}
            </div>
          </template>

          <template #image="{ image }">
            <img
              v-if="image"
              :src="image"
              alt="product"
              class="modal-body__image"
            >
            <svg-icon
              v-else
              name="no-image"
              class="modal-body__image"
            />
          </template>

          <template #price="{ price }">
            {{ formatPrice(price) }}
          </template>
        </grid>

        <div
          class="modal-body__pagination"
        >
          <pagination
            :data="pagination"
            :current-page="currentPage"
            @change="onPaginationChange"
          />
        </div>
      </template>
    </div>
  </modal>
</template>

<script>
import Heading from '@/components/Common/Heading.vue'
import CloseButton from '@/components/Interface/CloseButton.vue'
import Notification from '@/components/Interface/Notification.vue'
import Checkbox from '@/components/Interface/Checkbox.vue'
import Grid from '@/components/Interface/Grid.vue'
import Pagination from '@/components/Interface/Pagination'
import DownloadButton from '@/components/Interface/DownloadButton.vue'
import PaginationMixin from '@/mixins/pagination'
import Loader from '@/components/Common/Loader.vue'
import formatPrice from '@/utils/price-formatter'
import createCountFormatter from '@/utils/count-formatter'
import { productPricesColumns as columns } from '@/constants/products'
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions } = createNamespacedHelpers('products')

export default {
  mixins: [PaginationMixin],

  components: {
    Heading,
    CloseButton,
    Notification,
    Checkbox,
    Grid,
    Pagination,
    DownloadButton,
    Loader
  },

  data () {
    return {
      modalName: 'updatable-products-modal',
      columns,
      listId: null,
      isLoading: false,
      onlyBlocked: false
    }
  },

  watch: {
    onlyBlocked (checked) {
      this.fetchProducts({ only_errors: checked })
    }
  },

  computed: {
    ...mapState([
      'updatableProducts',
      'updatableProductsErrorsCount'
    ]),

    errorProducts () {
      return this.updatableProducts.filter(product => Object.keys(product.validation_errors).length)
    },

    errors () {
      return this.errorProducts.reduce((out, product) => {
        out[`-${product.id}`] = ['']
        return out
      }, {})
    },

    errorsCountText () {
      const formatErrorsCount = createCountFormatter({
        few: 'товаров',
        one: 'товару',
        two: 'товара'
      })

      return formatErrorsCount(this.updatableProductsErrorsCount)
    },

    updateDisabled () {
      return this.pagination.count === this.updatableProductsErrorsCount
    }
  },

  methods: {
    ...mapActions([
      'getUpdatableProducts',
      'downloadErrorProducts',
      'updateProducts'
    ]),

    formatPrice,

    onBeforeOpen (e) {
      this.listId = e.params.listId
      this.fetchProducts()
    },

    async onUpdateClick () {
      try {
        this.isUpdatePending = true

        const success = await this.updateProducts(this.listId)
        if (success) {
          this.$emit('success')
        }
      } finally {
        this.isUpdatePending = false
        this.$modal.hide(this.modalName)
      }
    },

    async fetchProducts (params) {
      try {
        this.isLoading = true

        const payload = {
          ...this.pagination,
          ...params,
          id: this.listId
        }
        this.pagination.count = await this.getUpdatableProducts(payload)
      } finally {
        this.isLoading = false
      }
    },

    async onDownloadErrorProducts () {
      try {
        this.isLoading = true

        const success = await this.downloadErrorProducts(this.listId)
        if (success) {
          this.$modal.show('export-modal', { openInNewTab: true })
        }
      } finally {
        this.isLoading = false
      }
    },

    onPaginationChange ({ page, limit, offset }) {
      this.currentPage = page
      this.pagination.limit = limit

      this.fetchProducts({ page, limit, offset })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-body
    padding 32px

    &__info
      padding 16px
      color #ffffff
      border-radius 8px
      background linear-gradient(92.15deg, #6c63ff 0%, #9e63ff 100%)

    &__info-text
      margin-right 25px

    &__info-btn
      flex-shrink 0

    &__actions
      display flex
      align-items center
      justify-content space-between
      margin-top 20px

    &__notification
      border 0
      margin-top 12px

    &__loader
      left 50%

    &__grid
      max-height 400px
      margin-top 12px
      overflow-y auto

    &__grid-meta
      margin-top 4px
      white-space nowrap
      font-size 12px
      color #9c9ca6

    &__image
      max-width 70px
      max-height 66px

    &__pagination
      margin-top 20px
</style>
