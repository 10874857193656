<template>
  <x-form-item
    small
    name="Статус"
    label="Статус"
    class="status-filter"
  >
    <x-loader
      v-if="loading"
      size="xs"
      class="status-filter__loader"
    />

    <x-select
      track-by="id"
      label="label"
      :value="status"
      :options="statuses"
      :limit="1"
      placeholder="Все статусы"
      @input="status => $emit('input', status)"
      @open="$emit('open')"
    />
  </x-form-item>
</template>

<script>
export default {
  props: {
    status: {
      type: [String, Object],
      default: ''
    },
    statuses: {
      type: Array,
      default: () => []
    },
    loading: Boolean
  }
}
</script>

<style lang="stylus" scoped>
  .status-filter
    position relative

    &__loader
      position absolute
      top 11px
      right 6px
      width initial
</style>
